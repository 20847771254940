.blogs .section-title {
  text-align: center;
  margin-bottom: 50px;
}

.blog-card {
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.blog-card:hover {
  transform: translateY(-5px);
}

.blog-image img {
  width: 100%;
  height: 220px;
  object-fit: cover;
  border-radius: 8px 8px 0 0;
  transition: transform 0.3s ease;
}

.blog-image img:hover {
  transform: scale(1.1);
}

.blog-body {
  padding: 16px;
}

.blog-badge {
  display: inline-block;
  padding: 2px 8px;
  border-radius: 50px;
  color: #fff;
  font-size: 12px;
  text-transform: uppercase;
  margin: 1px;
}

.blog-title {
  font-size: 18px;
  margin: 12px 0;
}

.blog-subtitle {
  font-size: 14px;
  margin-bottom: 16px;
  /* Ensure consistent height and overflow handling */
  max-height: 50px; /* Adjust based on your design */
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.5em; /* Adjust line-height to control text spacing */
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Number of lines to show before truncating */
  -webkit-box-orient: vertical;
}

.blog-author {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 8px;
}

.author-info-container {
  display: flex;
  align-items: center;
}

.author-image {
  border-radius: 50%;
  width: 48px;
  height: 48px;
  margin-right: 12px; /* Space between image and text */
}

.author-info {
  display: flex;
  flex-direction: column;
}

.author-info p {
  margin: 0;
}

.author-info .text-muted {
  color: #888;
}

.btn-read-more {
  line-height: 1em;
  letter-spacing: 0.06em;
  font-family: "Lato", sans-serif;
  font-weight: normal;
  font-size: 16px;
  text-decoration: none;
  color: #fff;
  background: #6e3fd7;
  display: inline-block;
  padding: 15px 12px;
  transition: background 200ms;
  border-radius: 4px;
}

.btn-read-more:hover {
  background: #f4f4f4;
}

.btn-read-more:after {
  font-family: "FontAwesome", sans-serif;
  font-weight: 300;
  content: "\f105"; /* FontAwesome chevron-right icon */
  margin-left: 20px;
  color: #f4f4f4;
  font-size: 18px;
  vertical-align: middle;
  transition: color 200ms;
}

.btn-read-more:hover:after {
  color: #6e3fd7;
}
