.get-quote-area {
  padding-top: 100px;
  /* padding-bottom: 100px; */
  background-color: #f9f9f9;
}

.section-title {
  text-align: center;
  margin-bottom: 50px;
}

.section-title .preheading {
  color: #1c1c1c;
  font-size: 18px;
  margin-bottom: 10px;
}

.section-title h1 {
  font-size: 36px;
  color: #333;
  margin-bottom: 20px;
}

.section-title p {
  font-size: 16px;
  color: #666;
}

.quote-form {
  background-color: #6e3fd7;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
}

.quote-form h2 {
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
}

.quote-form h3 {
  font-size: 24px;
  color: #fff;
}

.quote-form p {
  /* font-size: 16px; */
  color: #f9f8ffd5;
  margin-bottom: 30px;
}

.form-group {
  margin-bottom: 20px;
}

.form-control {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
}

.default-btn {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.default-btn:hover {
  background-color: #0056b3;
}

/* loader */

.loader {
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid #6e3fd7;
  width: 20px;
  height: 20px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
  margin: 0 auto;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.default-btn {
  background-color: #f9f8ffd5;
  color: #000;
}

.default-btn:hover {
  background-color: #5846f9;
  color: #fff;
  border-width: 1px;
  border-color: #f9f9f9;
}
